<template>
  <div
    class="_authorCard"
    :class="{
      'u-card2': context !== 'full',
    }"
    :data-context="context"
    :data-isself="is_self"
  >
    <component
      class="_linkTo"
      :is="context !== 'full' ? 'router-link' : 'span'"
      :to="author_url"
      @click.native="context !== 'full' ? $emit('navToPage') : ''"
    >
      <div class="_topbar">
        <div class="_cover">
          <CoverField
            :context="context"
            :cover="author.$cover"
            :title="$t('pick_portrait')"
            :preview_format="'circle'"
            :path="author.$path"
            :placeholder="author.name.substring(0, 2)"
            :can_edit="can_edit"
          />
        </div>

        <div v-if="context === 'full'" class="u-spacingBottom" />

        <div class="_text">
          <!-- :label="$t('name')" -->
          <div class="">
            <TitleField
              :field_name="'name'"
              :content="author.name"
              :path="author.$path"
              :required="true"
              :minlength="3"
              :maxlength="40"
              :tag="context === 'full' ? 'h2' : 'h3'"
              :can_edit="can_edit"
            />
            <div
              class="u-instructions"
              v-if="
                authorIsInstance({
                  field: '$admins',
                  folder_path: author.$path,
                })
              "
            >
              <small>
                <b-icon icon="shield-check" :aria-label="$t('admin')" />
                {{ $t("admin") }}
              </small>
            </div>
          </div>

          <div v-if="context === 'full'" class="u-spacingBottom" />

          <div v-if="author.group || can_edit">
            <TagsField
              :label="context === 'full' ? $t('group') : undefined"
              :field_name="'group'"
              :tag_type="'accountgroup'"
              :content="author.group"
              :path="author.$path"
              :can_edit="can_edit"
            />
          </div>

          <div v-if="context === 'full'" class="u-spacingBottom" />

          <div v-if="can_edit" class="u-spacingBottom">
            <TitleField
              :field_name="'email'"
              :label="context === 'full' ? $t('email') : undefined"
              :content="author.email"
              :path="author.$path"
              :required="$root.app_infos.instance_meta.require_mail_to_signup"
              :input_type="'email'"
              :autocomplete="'email'"
              :can_edit="can_edit"
            />
          </div>
          <div
            v-if="context === 'full' && (can_edit || !!author.presentation)"
            class="u-spacingBottom"
          >
            <CollaborativeEditor2
              :label="
                context === 'full' && (author.presentation || can_edit)
                  ? $t('presentation')
                  : undefined
              "
              :field_to_edit="'presentation'"
              :path="author.$path"
              :content="author.presentation"
              :custom_formats="['bold', 'italic', 'link']"
              :is_collaborative="false"
              :can_edit="can_edit"
            />
          </div>
        </div>

        <DetailsPane
          v-if="context === 'full' && (can_edit || !!author.$location)"
          :header="$t('location')"
          :is_open_initially="true"
          :has_items="!!author.$location"
          :icon="'map'"
          class="u-spacingBottom"
        >
          <PositionPicker
            :field_name="'$location'"
            :content="author.$location"
            :path="author.$path"
            :can_edit="can_edit"
          />
        </DetailsPane>

        <DetailsPane :header="$t('options')" :icon="'gear'" v-if="can_edit">
          <div
            v-if="
              is_instance_admin &&
              context === 'full' &&
              !authorIsInstance({
                field: '$admins',
                folder_path: author.$path,
              })
            "
            class="u-spacingBottom"
          >
            <button
              type="button"
              class="u-buttonLink"
              @click="show_settings_modal = true"
            >
              {{ $t("add_to_instance_admin") }}
            </button>
            <AdminLumaSettings
              v-if="show_settings_modal"
              :starting_tab="'administration_and_access_control'"
              @close="show_settings_modal = false"
            />
          </div>

          <div class="u-spacingBottom">
            <TitleField
              :field_name="'$password'"
              :label="$t('password')"
              :content="''"
              :path="author.$path"
              :required="true"
              :minlength="3"
              :maxlength="20"
              :input_type="'password'"
              :can_edit="can_edit"
            />
          </div>
          <div class="">
            <RemoveMenu
              :remove_text="$t('remove_account')"
              :remove_expl="$t('remove_account_expl')"
              @remove="removeAuthor"
            />
          </div>
        </DetailsPane>
      </div>
    </component>
  </div>
</template>
<script>
export default {
  props: {
    author: Object,
    context: String,
  },
  components: {
    AdminLumaSettings: () => import("@/components/AdminLumaSettings.vue"),
  },
  data() {
    return {
      show_settings_modal: false,
    };
  },
  i18n: {
    messages: {
      fr: {
        remove_author_expl:
          "Seuls le compte et le contenu du chutier seront supprimés, l’ensemble des contenus qui lui sont associés (documents de l’archive et collections) seront conservés.",
      },
      en: {
        remove_author_expl:
          "Only the account and chutier content will be removed, but archives and collections won’t.",
      },
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    is_self() {
      if (this.connected_as)
        return this.connected_as.$path === this.author.$path;
      return false;
    },
    can_edit() {
      return (
        (this.is_self || this.is_instance_admin) && this.context === "full"
      );
    },
    author_url() {
      return this.createURLFromPath(this.author.$path);
    },
  },
  methods: {
    async removeAuthor() {
      await this.$api.deleteItem({
        path: this.author.$path,
      });
      if (this.is_self) await this.$api.logoutFromFolder();
      this.$router.push("/@");
    },
  },
};
</script>
<style lang="scss" scoped>
._authorCard {
  border-radius: 3px;

  &[data-isself]:not([data-context="full"]) {
    border: 1px solid var(--c-bleumarine);
    background-color: var(--c-bleumarine_clair);
  }

  &:not([data-context="full"]) {
    background: white;
  }

  ._topbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: calc(var(--spacing) / 2);
    padding: calc(var(--spacing) / 2);

    > * {
      flex: 1 1 auto;

      &._cover {
        flex: 0 0 100px;
        aspect-ratio: 1/1;
      }
    }
  }

  &[data-context="full"] {
    ._topbar {
      flex-flow: column nowrap;
      align-items: stretch;

      ._cover {
        flex: 0 0 auto;
      }
    }
  }
}

._linkTo {
  text-decoration: none;
  color: inherit;
}

._cover {
  position: relative;
  overflow: hidden;
}

._text {
  // overflow: hidden;
  // display: flex;
  // flex-flow: column nowrap;
  // padding-bottom: calc(var(--spacing) / 2);
  // gap: calc(var(--spacing) / 2);

  ::v-deep {
    a {
      color: currentColor;
    }

    ._container {
      // line-height: 1;
    }
    ._content {
    }
  }
}
</style>
